import React, { useState, useRef, useEffect} from "react";
import {Container, Form, FormInput, FormGroup, Button, Row,  Card, CardHeader,CardBody, Modal, ModalBody, ModalHeader } from "shards-react";
import PlacedForCollectionsService from "../../../flux/services/PlacedForCollectionsService";
import loginStore from "../../../flux/stores/loginStore";
import AdminService from "../../../flux/services/AdminService";
import { useReactToPrint } from "react-to-print";
import DataTable, { Alignment } from "react-data-table-component";
import tableCustomStyles from "../../../utils/tableCustomStyles";
import tableBasicStyles from "../../../utils/tableBasicStyles";
import formatDollar from "../../../utils/formatDollar";
import { Redirect } from "react-router-dom";
import SearchService from "../../../flux/services/SearchService";

export default function MonthlyPFC(props) 
{

  const profile = loginStore.getProfile();
  //const [view, setView] = useContext(getViewContext());
  const [state, setState]= useState({
    months: !props.location.months?"":props.location.months,
  });
  const [data, setData] = useState(null);
  const [modal, setModal] = useState({
    modal: false
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
     content: () => componentRef.current,
     documentTitle: 'Monthly Meeting Report',
     
    });
    useEffect(() => {

      if (props.location.months != undefined)
      {
        let isMounted = true; 
        PlacedForCollectionsService.getPFCMonths(props.location.months).then( data => { if (isMounted) {setData(data)  
          } })
        return () => { isMounted = false };
      }  
    }, []);

  function HandleForm(event) {
    let newValue = event.target.value;
    switch(event.target.id)
    {
    case "#numOfMonths":
      setState( prevState =>
        (
          {
            ...prevState,
            months: newValue,
            view: "none"
          }
        )
      );
      break;
    default:
      break;
    }
    
  }
  function ModalState() {
    
    setModal( prevState =>
      (
        {
          modal: !modal.modal
        }
      )
    );
  }

  const handlePrintOriginal = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Monthly PFC Report',
    
  });

  async function HandleSubmit(e) {

      try {
        // set loading to true before calling API
        const data = await PlacedForCollectionsService.getPFCMonths(state.months);
        setData(data);
        //alert(JSON.stringify(data))
        if (data !== null)
        {
          let memberId = loginStore.getMemberId();
          let userName = loginStore.getUserName();
          let result = AdminService.setReportAccess(memberId, 0, 64, userName);
        }

      } catch (error) {
        // add error handling here
        console.log(error);
      }
      
  }

  async function onRowClickHandler(event) {
    //alert(JSON.stringify(event))
     let _response = await SearchService.getCustomerRecord(event.account_id);
   
     //alert(_response)
     if (_response == false)
       alert("Error returning Customer Record for CCI ID: " + event.account_id)
     else
     {
       setState( prevState =>
         (
           {
             ...prevState,
             view: "TradeReport",
           }
         )
       );
       
    /* setView( prevState =>
        (
          {
            ...prevState,
            view: "Report",
            ReturnReport: "MonthlyPFC",
            NumMonths: state.months
          }
        ) 
      );*/
     }
       
   }
 

  const columns = [
    {
      name: 'Member ID',
      selector: data => profile=="alacarte"?"":data.member_id.toString().substring(2,5),
      sortable: true,
      width: '12%'
    },
    {
      name: (<div class="w-100 text-end">CCI Id</div>),
      selector: data => data.account_id,
      cell: data => (<div class="w-100 text-end text-nowrap">{data.account_id}</div>),
      sortable: true,
      width: '10%'
    },
    {
      name: 'Company Name',
      sortable: true,
      selector: data => data.account_name,
      width: '30%'
    },
    {
      name: 'City',
      selector: data => data.city,
      width: '15%'
    },
    {
      name: 'State',
      selector: data => data.state,
      cell: data => data.state,
      sortable: true,
      width: '10%'
    },
    {
      id: data => data.date_entered,
      name: 'Date Entered',
      selector: data => data.date_entered,
      cell: data => (<div class="w-100 text-nowrap">{data.date_entered.toString().substring(5,7) + '/' + data.date_entered.toString().substring(8,10) + '/' + data.date_entered.toString().substring(0,4)}</div>) ,
      sortable: true,
      width: '13%'
    },
    {
      name: (<div class="w-100 text-end">PFC Amount</div>),
      selector: data => data.amount,
      cell: data => (<div class="w-100 text-end text-nowrap">{formatDollar(data.amount)}</div>),
      sortable: true,
      width: '10%'
    }
  ]

  
  const PFCDataTable = (props) =>
  {
    if (!data) return (
      null
    );

    return (
      <Row className="py-2 px-4">
          <div className="d-flex flex-row-reverse bd-highlight"> 
                <Button size="sm" theme="info" className="mb-2 mr-1" onClick={ModalState}>Print</Button>
        </div>
      <Card className="px-6">
          <CardHeader className="text-sm-center" id="PFC">
              <div class="text-center fs-5">Round Table Floor Covering Credit Group</div>
              <div class="text-center fs-6">PFC Monthly Report</div>
              <div class="text-center fs-6">All Customer's PFC Activity For The Past {state.months} Month(s)</div>
          </CardHeader>
          <CardBody>
      
      <DataTable customStyles={tableCustomStyles} columns={columns}
        onRowClicked={onRowClickHandler}
        highlightOnHover={true}
        data={data}
        ></DataTable>
        </CardBody>
        </Card>
        </Row>
    )
  }

  const PFCReportOriginal = (props) => {

    if (!data)
      return (
        <div></div>
      )
    return (
      <div>
        <div style={{height: "20px"}}></div>
        <h3 align="center">Round Table Floor Covering Credit Group</h3>
        <h4 align="center">PFC Monthly Report</h4>
        <div align="center" class="style4">&nbsp;</div>
        <h6 align="center">All Customer's PFC Activity For The Past {state.months} Month(s)</h6>
        <hr classname="black"></hr>
        <Row className="px-5">
                  <DataTable customStyles={tableBasicStyles} columns={columns}
                  onRowClicked={onRowClickHandler}
                  highlightOnHover={true}
                  data={data}
                  ></DataTable>
               {/** <table width="600" border="0" cellpadding="0">
                    <tr className="style1" height="30">
                    <th width="92" align="center">Member Id</th>
                    <th width="80">CCI Id</th>
                    <th width="120">Company Name</th>
                    <th width="108" align="center">City</th>
                    <th width="63">State</th>
                    <th width="100">Date Entered</th>
                    <th align="right" width="102"><div class="w-100 text-end">PFC Amount</div></th>
                    </tr>
                <tbody> 
                  
                  {data.map((data) => (
                      <tr className="style2"> 
                      <td>{data.member_id}</td>
                      <td>{data.account_id}</td>
                      <td>{data.account_name}</td>
                      <td>{data.city}</td>
                      <td>{data.state}</td>
                      <td>{data.date_entered.toString().substring(5,7) + '/' + data.date_entered.toString().substring(8,10) + '/' + data.date_entered.toString().substring(0,4)}</td>
                      <td align="right">{data.amount}</td></tr>
                    ))
                  }
    
                </tbody>
                </table>*/} 
        </Row>

      </div>
    )
}

  if (state.view == "TradeReport")
  return (

    <div>
      <Redirect to={{
            pathname: '/ViewTradeReport',
            returnValue: 'MonthlyPFC',
            months: state.months 
            }} />
    </div>
  )

    return (
    <Container>
      <Row className="py-2 px-5">
        <Card className="px-6">
          <CardHeader className="border-bottom">
            <h6>
              View Monthly PFC
            </h6>
          </CardHeader>
          <CardBody>
          
            <Form className="d-flex justify-content-left">
            <FormGroup>
              <label for="#numOfMonths" className="px-2 pb-2">Number Of Months:</label>
              <FormInput type="text" id="#numOfMonths" onChange={HandleForm.bind(this)} value={state.months} />
              
            </FormGroup>
            </Form>
            <div className="d-flex justify-content-left">
            <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={HandleSubmit} >Submit</Button>
            </div>
          </CardBody>
        </Card>
      </Row>
      <PFCDataTable></PFCDataTable>
      <Modal open={modal.modal} className="modal-lg" >
          <ModalHeader>Monthly Placed for Collections</ModalHeader>
          <ModalBody>
          <div className="d-flex gap-2 flex-row-reverse bd-highlight">
          <Button size="sm" theme="info" className="mb-2 mr-1" onClick={ModalState}>Close</Button>
          <Button size="sm" theme="info" className="mb-2 mr-1" onClick={handlePrintOriginal}>Print</Button>
          </div>
          <div
              ref={componentRef}
              className="print-component"
            >
          <PFCReportOriginal></PFCReportOriginal>
            
          </div>
          </ModalBody>
        </Modal>
        
            
      </Container>
    )
  }

