import React,{ useState, useEffect, useRef} from "react";
import { Row,  Card, Col, CardBody, CardHeader, Button, Modal, ModalBody, ModalHeader} from "shards-react";
import ClearingNamesService from "../../../flux/services/ClearingNamesService";
import DataTable from "react-data-table-component";
import {useReactToPrint} from "react-to-print"
import tableCustomStyles from "../../../utils/tableCustomStyles";
import TradeReportOriginal from "./TradeReportOriginal";
 
export default function ClearingReport (props)
{

  
  const profile = props.profile;
  let [refresh, setRefresh] = useState({ refresh: props.refresh})
  const [state, setState]= useState({
    memberId: props.memberId,
    view: props.view,
    profile: props.profile
  });
  const [modal, setModal] = useState({
    modal: false,
    reportType: ""
  });

  const [data, setData] = useState(null);
  const [specialNamesData, setSpecialNamesData] = useState(null)
  const originalTradeReportRef = useRef();
  useEffect(() => {
    let ismounted = true;
//    async function getClearingNames(memId, profile){

      try {

        let data = null;
        // set loading to true before calling API
        if (profile === 'admin')
          /*data = */ ClearingNamesService.getClearingNamesAdmin().then(data => {if (ismounted) setData(data)});
        else
         /* data = */ClearingNamesService.getClearingNames(state.memberId).then(data => {if (ismounted) setData(data)});

        /*setData(data);*/

      } catch (error) {
        console.log(error);
      }
      try {
        //let data1 = null;
        // set loading to true before calling API
        if (profile === 'admin')
             ClearingNamesService.getSpecialClearingNamesAdmin().then(data => {if (ismounted) setSpecialNamesData(data)});
        else
          ClearingNamesService.getSpecialClearingNames(state.memberId).then(data => {if (ismounted) setSpecialNamesData(data)});

        //setSpecialNamesData(data1);

      } catch (error) {
        console.log(error);
      }
    //}
    
  //  getClearingNames(state.memberId, profile);
    return () => { ismounted = false };

  }, [refresh, props.refresh]);

  function ModalState() {
    setModal( 
        {
          
          reportType: "",
          modal: !modal.modal
        }
      )
  }
  

  function GenerateDetails(reportType)
  {

    setModal( 
      {
          modal: true,
          reportType: reportType
      }
    );
  }

 const handlePrintOriginal = useReactToPrint({
      content: () => originalTradeReportRef.current,
      documentTitle: 'Trade Report',
      
    });


  //Remove the Clearing Name
  async function DeleteClearingName(clearingNameId)
  {
    await ClearingNamesService.deleteClearingNames(clearingNameId);
   /* try {
      // set loading to true before calling API
      const data = await ClearingNamesService.getClearingNames(state.memberId);
      setData(data);

    } catch (error) {
      // add error handling here
      console.log(error);
    }
*/
    setRefresh( {refresh: !refresh.refresh});
  }

 //add a delete button for the member to delete its own meeting comment
 const DeleteButton = (props) =>
 {
   //alert(JSON.stringify(props))
   if(props.member_id == props.loggedin_Member_id || props.profile == 'admin')
     return(
       <div className="py-1">
        <button type="button" style={ { height: 28, width: 50, padding: 5} } className="btn btn-secondary btn-sm" onClick={() => DeleteClearingName(props.clearing_name_id)}>Delete</button>

       </div>
     )

   return(<div></div>)
 }

  const columns = [
    {
      name: 'Member',
      selector: data => profile=="alacarte"?"":data.member_id.toString().substring(2,5),
      sortable: true,
      width: '8%'
    },
    {
      name: (<div className="w-100 text-end">Date Entered</div>),
      selector: data => data.date_entered,
      cell: data => data.date_entered.toString().substring(5,7) + '/' + data.date_entered.toString().substring(8,10) + '/' + data.date_entered.toString().substring(0,4),
      sortable: true,
      width: '10%'
    },
    {
      name: 'CCI Number',
      sortable: true,
      selector: data => data.cci_account_id,
      width: '10%'
    },
    {
      name: 'Name',
      selector: data => data.account_name,
      sortable: true,
      width: '18%'
    },
    {
      name: 'Phone',
      selector: data => data.phone,
      cell: data => data.phone,
      sortable: true,
      width: '10%'
    },
    {
      
      name: 'Clearing Name',
      selector: data => data.clearing_name,
      cell: data => data.clearing_name ,
      sortable: true,
      width: '15%'
    },
    {
      name: 'Clearing Reason',
      selector: data => data.attribute_value + ' - ' + data.attribute_description,
      cell: data => data.attribute_value + ' - ' + data.attribute_description ,
      sortable: true,
      width: '20%'
    },
    {
      name: state.view=='Report'?'':'Delete',
      cell: data => state.view=='Report'?null:<DeleteButton member_id={data.member_id} profile={state.profile} loggedin_Member_id={state.memberId}  clearing_name_id={data.clearing_name_id}></DeleteButton>,
      width: '10%'
    }
  ]

  const NamesDataTable = (props) =>
  {
  
    let clearingData = null;
    if (props.view === "special")
      clearingData = specialNamesData;
    else
      clearingData = data;

    if (!clearingData) return (
      <span>Data not available</span>
    );

    return (
        <Row className="px-5">
            <DataTable customStyles={tableCustomStyles} columns={columns}
              data={clearingData}
              ></DataTable>
        </Row>)
  }

  const  NameDetails = (props) =>
    {

      if (props.view === "")
        return (
          <div></div>
        )
    
     let clearingData = null;
      if (props.view === "special")
        clearingData = specialNamesData;
      else
        clearingData = data;
  
      if (!clearingData) return (
        <span>Data not available</span>
      );

      let CCIids = []
      for(var i = 0; i < clearingData.length; i++)
      {
        CCIids[i] = clearingData[i].cci_account_id;
      }
    
      let uniqueCCIids = [...new Set(CCIids)] 


  
      return (
          <div>
           {clearingData.map((clearingData) => (
                <TradeReportOriginal accountId={clearingData.cci_account_id} customerRecord={clearingData} reportType="ClearingReport" ></TradeReportOriginal>
                
              ))}
          </div>)
    }


  return (
    
    <Card small className="mb-3">
     
        <CardHeader className="border-bottom">
          <h5>
            Clearing Names
          </h5>
        </CardHeader>
        <CardBody>
        <Modal open={modal.modal} className="modal-lg" >
          <ModalHeader>Customer Trade Report</ModalHeader>
          <ModalBody>
          <div className="d-flex gap-2 flex-row-reverse bd-highlight">
          <Button size="sm" theme="info" className="mb-2 mr-1" onClick={() => ModalState()}>Close</Button>
          <Button size="sm" theme="info" className="mb-2 mr-1" onClick={() => handlePrintOriginal()}>Print</Button>
          </div>
          <div
              ref={originalTradeReportRef}
              className="print-component"
            >
          <NameDetails view={modal.reportType}></NameDetails>
            
          </div>
          </ModalBody>
        </Modal>
        
        <Col>
          <CardHeader className="border-bottom mb-2">
          <div className="text-center fs-6 fw-bold">Special Names</div>
          <div className="d-flex gap-3 flex-row-reverse bd-highlight"> 
          <Button size="sm" theme="info" className="mb-2 mr-1" onClick={() => GenerateDetails("special")}>Generate Details</Button>
                  </div>
          </CardHeader>
          <NamesDataTable view="special" data={props}></NamesDataTable>
          <CardHeader className="border-bottom mb-2">
          <div className="text-center fs-6 fw-bold">Clearing Names</div>
          <div className="d-flex gap-3 flex-row-reverse bd-highlight"> 
          <Button size="sm" theme="info" className="mb-2 mr-1" onClick={() => GenerateDetails("clearing")}>Generate Details</Button>
                  </div>
          </CardHeader>
          <NamesDataTable data={props}></NamesDataTable>
          </Col>
        </CardBody>
    </Card>
  )
}

