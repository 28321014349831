import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import SmallStats from "../../../components/common/SmallStats";
import UsersOverview from "../../../components/blog/UsersOverview";
import UsersByDevice from "../../../components/blog/UsersByDevice";
import DashboardPieGraph from "./DashboardPieGraph";
import loginStore from "../../../flux/stores/loginStore";
import ScoreAgainstIndustryGraph from "./ScoreAgainstIndustryGraph";
import DashboardService from "../../../flux/services/DashboardService";
import TotalAccountsGraph from "./TotalAccountsGraph";
import PaymentHistoryGraph from "./PaymentHistoryGraph";
import MemberAlerts from "./MemberAlerts";

export default function DashboardSummary (props) {

    const [totalAccounts, setTotalAccounts] = useState(
      {
        label: 'Total',
        percentage: 0,
        value: 0,
        lineGraphValues: []
      }
    )
    const[smallStats, setSmallStats] = useState(
      {
        items: []
      }
    ) 

   

   

  
   /*let smallStats = [
      
      {
        label: "ANTC test",
        value: "1532",
        percentage: "12.4",
        increase: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(23,198,113,0.1)",
            borderColor: "rgb(23,198,113)",
            data: [1, 2, 3, 3, 3, 4, 4]
          }
        ]
      },
      {
        label: "A/Disc",
        value: "352",
        percentage: "5.8%",
        increase: false,
        decrease: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(255,180,0,0.1)",
            borderColor: "rgb(255,180,0)",
            data: [2, 3, 3, 3, 4, 3, 3]
          }
        ]
      },
      {
        label: "A/Ppt",
        value: "215",
        percentage: "2.71%",
        increase: false,
        decrease: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(255,65,105,0.1)",
            borderColor: "rgb(255,65,105)",
            data: [1, 7, 1, 3, 1, 4, 8]
          }
        ]
      },
      {
        label: "Disc",
        value: "182",
        percentage: "2.4%",
        increase: false,
        decrease: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgb(0,123,255,0.1)",
            borderColor: "rgb(0,123,255)",
            data: [3, 2, 3, 2, 4, 5, 4]
          }
        ]
      },
      {
        label: "Ppt",
        value: "182",
        percentage: "2.4%",
        increase: false,
        decrease: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgb(0,123,255,0.1)",
            borderColor: "rgb(0,123,255)",
            data: [3, 2, 3, 2, 4, 5, 4]
          }
        ]
      }
    ]*/

    const GraphItems = (props) =>
    {
      //alert (JSON.stringify(smallStats))
      return (
        <div className="row justify-content-start">

            
          <Col lg="9">
            
            <Row>
              <div className="d-flex justify-content-left gap-3 mb-4">
              {/*<TotalAccountsGraph memberId={props.memberId} />*/}
              <PaymentHistoryGraph memberId={props.memberId} paymentHistory="All" backgroundColor="rgba(0, 184, 216, 0.1)" borderColor="rgb(0, 184, 216)" />
              <PaymentHistoryGraph memberId={props.memberId} paymentHistory="No Exp" backgroundColor="rgba(150,255,152,0.1)" borderColor="rgb(150,255,152)" />
              <PaymentHistoryGraph memberId={props.memberId} paymentHistory="Antc" backgroundColor="rgba(255,180,0,0.1)" borderColor="rgb(255,180,0)" />
              <PaymentHistoryGraph memberId={props.memberId} paymentHistory="A/Disc" backgroundColor="rgba(255,65,105,0.1)" borderColor="rgb(255,65,105)" />
              <PaymentHistoryGraph memberId={props.memberId} paymentHistory="A/Ppt" backgroundColor="rgba(0,123,255,0.1)" borderColor="rgb(0,123,255)" />
              </div>
            </Row>
            <Row>
            <div className="d-flex justify-content-left gap-3 mb-4">
              <PaymentHistoryGraph memberId={props.memberId} paymentHistory="Disc" backgroundColor="rgba(121,41,100,0.1)" borderColor="rgb(121,41,100)" />
              <PaymentHistoryGraph memberId={props.memberId} paymentHistory="D/Ppt" backgroundColor="rgba(0,123,255,0.1)" borderColor="rgb(0,123,255)" />
              <PaymentHistoryGraph memberId={props.memberId} paymentHistory="Ppt" backgroundColor="rgba(150,255,152,0.1)" borderColor="rgb(150,255,152)" />
              <PaymentHistoryGraph memberId={props.memberId} paymentHistory="Slo" backgroundColor="rgba(206,173,255,0.1)" borderColor="rgb(206,173,255)" />
              </div>
            </Row>
            <Row>
            <div className="d-flex justify-content-left gap-3 mb-4">
            <ScoreAgainstIndustryGraph memberId={loginStore.getMemberId()}/>
            </div>
            </Row>
          </Col>
        <Col lg="3">
          <div className="row px-2">
          <MemberAlerts></MemberAlerts>
          </div>
          <div className="row py-4 px-2">
          
          <DashboardPieGraph memberId={loginStore.getMemberId()} />
          </div>
          
        </Col>
        </div>
      )
    }



    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle title="Account Data Overview" subtitle="Dashboard" className="text-sm-left mb-3" />
        </Row>

        {/* Small Stats Blocks */}
        <GraphItems memberId={props.memberId}/>

          {/* Users Overview */}


          {/* Replace Graph here */}

      </Container>
    )


  }

