
const data = [
    
  ];
  const statuses = [
    {
      index: 1,
      title: "Current Company",
      status: "Current Company",
      color: "#FAFAFA",
    },
    {
      index: 2,
      title: "New Company",
      status: "New Company",
      color: "#FAFAFA",
    },
  ];
  export { data, statuses };
  